import React from 'react';

import './inventory-information-attribute.scss';

export default ({ icon, mainText, secondaryText }) => {
  return (
    <article className="media">
      <figure className="media-left">
        <div className="media-icon">
          <i className={icon} />
        </div>
      </figure>
      <div className="media-content">
        <div className="is-size-6 has-text-weight-semibold">{mainText}</div>
        <div className="is-size-6">{secondaryText}</div>
      </div>
    </article>
  );
};
