import React from 'react';

import './invnetory-information-attribute-list.scss';

export default ({ icon, mainText, textList }) => {
  return (
    <article className="media">
      <figure className="media-left">
        <div className="media-icon">
          <i className={icon} />
        </div>
      </figure>
      <div className="media-content">
        <div className="is-size-6 has-text-weight-semibold">{mainText}</div>
        <div className="is-size-6">
          <ul>
            {textList.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  );
};
