import React from 'react';
import * as NumberAbbrevation from 'number-abbreviate';

import InventoryInformationAttribute from '../../atoms/InventoryInformationAttribute/inventory-information-attribute';
import InventoryInformationAttributeList from '../../atoms/InventoryInformationAttributeList/invnetory-information-attribute-list';
import { assetTypes } from '../../../models/assetTypes';

import './inventory-info.scss';

const NumAbbr = new NumberAbbrevation(['K', 'M', 'B', 'T']);

export default ({ inventory }) => {
  return (
    <div>
      {inventory.averageDailyImpressions ? (
        <div>
          <InventoryInformationAttribute
            icon="far fa-2x fa-eye"
            mainText={`${NumAbbr.abbreviate(
              inventory.averageDailyImpressions,
              1,
            )} Daily Impressions`}
            secondaryText={
              inventory.averageDailyImpressions > 10000 &&
              'This asset is in a prime location for views!'
            }
          />
        </div>
      ) : null}

      {inventory.type ? (
        <div className="inventory-info-attribute-instance">
          <InventoryInformationAttribute
            icon="fas fa-desktop"
            mainText={assetTypes[inventory.type].displayText}
            secondaryText={
              inventory.dimensions &&
              `${inventory.dimensions.width}ft x ${inventory.dimensions.height}ft.`
            }
          />
        </div>
      ) : null}

      {inventory.direction ? (
        <div className="inventory-info-attribute-instance">
          <InventoryInformationAttribute
            icon="far fa-compass"
            mainText={`Facing ${inventory.direction}`}
            secondaryText={`This asset is facing ${inventory.direction}.`}
          />
        </div>
      ) : null}

      {inventory.notes && inventory.notes.length ? (
        <div className="inventory-info-attribute-instance">
          <InventoryInformationAttributeList
            icon="far fa-sticky-note"
            mainText="Notes"
            textList={inventory.notes}
          />
        </div>
      ) : null}
    </div>
  );
};
