import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import InventoryInformationAttribute from '../InventoryInformationAttribute/inventory-information-attribute';

import './inventory-information-attribute-accordion.scss';

export default ({
  accordionHeaderInformation: { icon, mainText, secondaryText } = {},
  tabularData,
}) => {
  const [accordionIsActive, setAccordionIsActive] = useState(() => {
    if (tabularData) {
      return true;
    } else return false;
  });

  const toggleAccordion = () => setAccordionIsActive(!accordionIsActive);

  return (
    <section className="accordions attribute-accordion">
      <article
        className={classNames('accordion', {
          'is-active': accordionIsActive,
        })}
      >
        <div className="accordion-header">
          <InventoryInformationAttribute
            icon={icon}
            mainText={mainText}
            secondaryText={secondaryText}
          />
          {tabularData && (
            <button
              className="button is-white"
              aria-label="toggle"
              onClick={toggleAccordion}
            >
              {accordionIsActive ? (
                <Fragment>
                  <span>Collapse</span>
                  <span className="icon">
                    <i className="fas fa-chevron-up" />
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <span>Expand</span>
                  <span className="icon">
                    <i className="fas fa-chevron-down" />
                  </span>
                </Fragment>
              )}
            </button>
          )}
        </div>
        {tabularData && (
          <div className="accordion-body">
            <div className="accordion-content">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    {tabularData.columns.map((element) => (
                      <th key={`${mainText}-${element}`}>{element}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tabularData.data.map((row) => (
                    <tr key={row.name}>
                      <td>{row.name}</td>
                      <td>
                        <NumberFormat
                          value={row.total ? row.total : 0}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={row.male ? row.male : 0}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={row.female ? row.female : 0}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </article>
    </section>
  );
};
