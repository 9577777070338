import React from 'react';
import { Router } from '@reach/router';

import Layout from '../utils/layout';
import { withFirebase } from '../utils/Firebase';

import InventoryDetails from '../components/scenes/InventoryDetails/inventory-details';

const InventoryDetailsPage = withFirebase(InventoryDetails);

export default () => {
  return (
    <Layout>
      <Router basepath="/inventory-details">
        <InventoryDetailsPage path="/:id" />
      </Router>
    </Layout>
  );
};
