import React, { useState } from 'react';
import 'react-dates/initialize';

import { DateRangePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import './calendar.scss';

const MS_IN_DAY = 86400000;

export default ({
  period,
  setPeriod,
  setDateRangeIsSet,
  setDateRangeIsSetAnimation,
}) => {
  const [focusedInput, setFocusedInput] = useState(undefined);

  const onDatesChange = ({ startDate, endDate }) =>
    setPeriod({
      numberOfDays:
        startDate && endDate
          ? Math.ceil(endDate.diff(startDate) / MS_IN_DAY) + 1
          : 0,
      value: {
        startDate,
        endDate,
      },
    });

  const onFocusChange = (FocusedInput) => setFocusedInput(FocusedInput);

  const onCloseCalendar = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setDateRangeIsSet(true);
      setDateRangeIsSetAnimation(true);
    } else setDateRangeIsSet(false);
  };

  return (
    <DateRangePicker
      startDate={period.value.startDate}
      startDateId="your_unique_start_date_id"
      endDate={period.value.endDate}
      endDateId="your_unique_end_date_id"
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      hideKeyboardShortcutsPanel={true}
      onClose={onCloseCalendar}
    />
  );
};
