import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Button from '../../atoms/Button/button';

import { CART } from '../../../constants/routes';

import './add-to-cart-modal.scss';

export default ({ modalIsActive, closeModal }) => {
  return (
    <div
      className={classNames('modal', {
        'is-active': modalIsActive,
      })}
    >
      <div className="modal-background" />
      <div className="modal-content atc-modal">
        <p className="atc-modal__content-paragraph">
          A new booking request has been added to your cart.
        </p>
        <div className="atc-modal__content-action">
          <span>
            <Button
              text="Keep browsing"
              className="btn-success"
              onClick={closeModal}
            />
          </span>
          <span>
            <Link to={CART} className="btn-danger">
              Go to the cart
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
