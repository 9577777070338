import React, { Fragment, useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import * as moment from 'moment';

import { AuthUserContext } from '../../../utils/Session';
import { grpCalculator } from '../../../utils/helpers/grp';
import Calendar from '../../molecules/Calendar/calendar';
import Button from '../../atoms/Button/button';

import { SIGN_IN, SIGN_UP } from '../../../constants/routes';

import './inventory-add-to-cart.scss';

const MS_IN_DAY = 86400000;
const grpDefinition =
  'Gross Rating Points (GRP) are the total number of in-market impressions delivered by an Out of Home (OOH) or outdoor billboard campaign schedule expressed as a percentage of a market population and is a measure of advertising impact. A one rating point represents impressions equal to 1% of the market population.';

const createAuthenticationErrorMsg = (inventoryId) => {
  return (
    <Fragment>
      <p>You must be logged in to view your cart.</p>
      <p>
        <button
          className="link-button"
          onClick={() =>
            navigate(
              `${SIGN_IN}/?redirectURL=/inventory-details/${inventoryId}`,
            )
          }
        >
          Sign in
        </button>{' '}
        to view your cart or{' '}
        <button
          className="link-button"
          onClick={() =>
            navigate(
              `${SIGN_UP}/?redirectURL=/inventory-details/${inventoryId}`,
            )
          }
        >
          Sign up
        </button>{' '}
        if you don’t have an account.
      </p>
    </Fragment>
  );
};

export default ({
  inventoryId,
  inventory,
  firebase,
  openModal,
  createMessage,
}) => {
  const authUser = useContext(AuthUserContext);
  const [dateRangeIsSet, setDateRangeIsSet] = useState(false);
  const [dateRangeIsSetAnimation, setDateRangeIsSetAnimation] = useState(false);
  const [pricingPerPeriod, setPricingPerPeriod] = useState(0);
  const [grp, setGrp] = useState(0);

  const [period, setPeriod] = useState({
    numberOfDays: 0,
    value: {
      startDate: undefined,
      endDate: undefined,
    },
  });

  const addToCart = () => {
    if (!authUser) {
      return createMessage({
        type: 'warning',
        title: 'Authentication Error',
        message: createAuthenticationErrorMsg(inventoryId),
      });
    } else {
      if (period.numberOfDays === 0) {
        return createMessage({
          type: 'warning',
          title: 'Unable to process',
          message: 'You must choose a date range.',
        });
      } else if (
        inventory.minBookingDays &&
        period.numberOfDays < inventory.minBookingDays
      ) {
        return createMessage({
          type: 'warning',
          title: 'Unable to process',
          message: `The number of requested day(s) is lower than the minimum booking day(s). The minimum booking day(s) for this inventory is ${inventory.minBookingDays} day(s).`,
        });
      } else if (
        inventory.maxBookingDays &&
        period.numberOfDays > inventory.maxBookingDays
      ) {
        return createMessage({
          type: 'warning',
          title: 'Unable to process',
          message: `The number of requested day(s) is more than the maximum booking day(s). The maximum booking day(s) for this inventory is ${inventory.maxBookingDays} day(s).`,
        });
      }
      return firebase.addToCart(
        authUser,
        inventory,
        period,
        openModal,
        createMessage,
      );
    }
  };

  useEffect(() => {
    const tempPricing = (
      period.numberOfDays * inventory.pricing.dailyRate
    ).toFixed(2);
    setPricingPerPeriod(tempPricing);

    setGrp(grpCalculator(inventory, period.numberOfDays));
  }, [period.numberOfDays]);

  useEffect(() => {
    if (inventory.minBookingDays && period.value.startDate) {
      const tempEndDate = moment(period.value.startDate).add(
        inventory.minBookingDays - 1,
        'days',
      );
      setPeriod({
        numberOfDays:
          Math.ceil(tempEndDate.diff(period.value.startDate) / MS_IN_DAY) + 1,
        value: {
          startDate: period.value.startDate,
          endDate: tempEndDate,
        },
      });
      setDateRangeIsSet(false);
    }
  }, [period.value.startDate]);

  return (
    <div className="calendar-container">
      <div className="calendar-container__header">
        <p className="is-size-6 has-text-weight-semibold">
          Requested Booking Time
        </p>
      </div>
      <div className="calendar-container__pricing">
        {authUser ? (
          inventory.pricing && inventory.pricing.dailyRate ? (
            <p>
              <span className="is-size-6 has-text-weight-semibold">
                Starting from:{' '}
              </span>
              <span className="is-size-5 has-text-weight-semibold">
                ${inventory.pricing.dailyRate} / day
              </span>
            </p>
          ) : (
            <Fragment>
              <p className="is-size-6 has-text-weight-semibold">
                No pricing available for this asset at the moment.
              </p>
            </Fragment>
          )
        ) : null}
      </div>
      <div className="calendar-container__calendar">
        {inventory.minBookingDays && period.value.startDate && (
          <div className="calendar-container__calendar__info">
            <span className="has-text-weight-semibold">
              NOTE:
              <br />{' '}
            </span>
            The Minimum Booking for this asset is{' '}
            <span className="has-text-weight-semibold">
              {inventory.minBookingDays} Day(s)
            </span>
            .
            <br />
            {dateRangeIsSet ? (
              <div
                className={classNames({
                  'calendar-container__calendar__info__picked-date-range-message': dateRangeIsSetAnimation,
                })}
                onAnimationEnd={() => setDateRangeIsSetAnimation(false)}
              >
                Start date is{' '}
                <span className="has-text-weight-semibold">
                  {moment(period.value.startDate).format('DD MMMM, YYYY')}
                </span>{' '}
                - End date is{' '}
                <span className="has-text-weight-semibold">
                  {moment(period.value.endDate).format('DD MMMM, YYYY')}
                </span>
                .
              </div>
            ) : (
              <Fragment>
                Thus, the End Date has been auto-set to{' '}
                <span className="has-text-weight-semibold">
                  {moment(period.value.startDate)
                    .add(inventory.minBookingDays - 1, 'days')
                    .format('DD MMMM, YYYY')}
                </span>
                .
              </Fragment>
            )}
          </div>
        )}
        <div>
          <Calendar
            minBookingDays={inventory.minBookingDays}
            period={period}
            setPeriod={setPeriod}
            setDateRangeIsSet={setDateRangeIsSet}
            setDateRangeIsSetAnimation={setDateRangeIsSetAnimation}
          />
        </div>
      </div>
      <div className="calendar-container__content">
        <div className="calendar-container__content__info">
          <div className="level is-marginless">
            <div className="level-right is-marginless">Requested Dates:</div>
            <div className="level-left">
              {period.numberOfDays
                ? authUser && inventory.pricing && inventory.pricing.dailyRate
                  ? ` ${period.numberOfDays} Day(s) x $${inventory.pricing.dailyRate} / Day`
                  : ` ${period.numberOfDays} Day(s)`
                : 'N/A'}
            </div>
          </div>
          <div className="level is-marginless">
            {inventory.marketInfo &&
            inventory.marketInfo.population &&
            inventory.marketInfo.population.total &&
            grp ? (
              <Fragment>
                <div className="level-right is-marginless">
                  <p>
                    Estimated Total GRP
                    <span
                      className="has-tooltip-multiline"
                      data-tooltip={grpDefinition}
                    >
                      <i className="fas fa-info-circle calendar-container__content__grp-tooltip"></i>
                    </span>
                  </p>
                </div>
                <div className="level-left">{grp}</div>
              </Fragment>
            ) : null}
          </div>
          <div className="level is-marginless">
            {authUser && inventory.pricing && inventory.pricing.dailyRate ? (
              <Fragment>
                <div className="level-right is-marginless">
                  <p className="has-text-weight-bold">Estimated Total Price</p>
                </div>
                <div className="level-left">
                  {period.numberOfDays && `$${pricingPerPeriod}`}
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
        <div className="calendar-container__action">
          <Button
            className="button is-fullwidth is-success"
            onClick={addToCart}
            text="Add to cart"
          />
        </div>
      </div>
    </div>
  );
};
