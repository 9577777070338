import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import Loading from '../../atoms/Loading/loading';
import DashboardTemplate from '../../organisms/DashboardTemplate/dashboard-template';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';
import ImageGrid from '../../molecules/ImageGrid/image-grid';
import InventorySupplierInfo from '../../molecules/InventorySupplierInfo/inventory-supplier-info';
import InventoryInfo from '../../organisms/InventoryInfo/inventory-info';
import InventoryAudienceInfo from '../../organisms/InventoryAudienceInfo/inventory-audience-info';
import InventoryAddToCart from '../../organisms/InventoryAddToCart/inventory-add-to-cart';
import AddToCartModal from '../../molecules/AddToCartModal/add-to-cart-modal';
import Divider from '../../atoms/Divider/divider';
import { assetTypes } from '../../../models/assetTypes';

import './inventory-details.scss';

export default ({ id, firebase }) => {
  const [initFirebase, setInitFirebase] = useState(false);
  const [inventory, setInventory] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [response, setResponse] = useState(undefined);

  const getInventory = async (IID) => {
    const temp = await firebase.inventory(IID);
    if (temp.exists) {
      setInventory(temp.data());
      return setLoading(false);
    } else {
      navigate('/404');
    }
  };

  const openModal = () => setModalIsActive(true);

  const closeModal = () => setModalIsActive(false);

  const createMessage = ({ type, title, message, linkPath, linkText }) =>
    setResponse({ type, title, message, linkPath, linkText });

  const deleteMessage = () => setResponse(undefined);

  useEffect(() => {
    if (firebase && !initFirebase) {
      setInitFirebase(true);
      getInventory(id);
    }
  }, [firebase, initFirebase]);

  if (loading) return <Loading />;

  return (
    <DashboardTemplate>
      {response && (
        <ResponseHandler response={response} deleteMessage={deleteMessage} />
      )}
      <div className="columns">
        <div className="column is-three-fifths">
          <div>
            <ImageGrid images={inventory.images} />
          </div>
          <div className="inventory-address">
            <p className="inventory-address__text">
              Billboard {inventory.internalId}
              {inventory.address && ` - ${inventory.address}`}
              {inventory.city && ` - ${inventory.city}`}
            </p>
          </div>
          <div className="inventory-info-section-wrapper">
            <InventorySupplierInfo
              firebase={firebase}
              inventory={inventory}
              assetTypes={assetTypes}
            />
          </div>
          <Divider />
          <div className="inventory-info-section-wrapper">
            <InventoryInfo inventory={inventory} />
          </div>
          <Divider />
          <div className="inventory-info-section-wrapper">
            <InventoryAudienceInfo marketInfo={inventory.marketInfo} />
          </div>
        </div>
        <div className="column is-two-fifths">
          <InventoryAddToCart
            inventoryId={id}
            inventory={inventory}
            firebase={firebase}
            openModal={openModal}
            createMessage={createMessage}
          />
        </div>
      </div>
      <AddToCartModal modalIsActive={modalIsActive} closeModal={closeModal} />
    </DashboardTemplate>
  );
};
