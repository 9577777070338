import React from 'react';

import Placeholder from '../../../assets/inventory-placeholder.png';

import './image-grid.scss';

export default ({ images }) => {
  const singleImage = images && images[0] ? images[0] : Placeholder;

  return (
    <div className="tile is-ancestor">
      {images && images.length > 1 ? (
        <>
          <div className="tile is-parent is-8">
            <div className="tile image-wrapper">
              <img src={images[0] ? images[0] : Placeholder} alt="img" />
            </div>
          </div>
          <div className="tile is-parent is-vertical">
            <div className="tile is-child image-wrapper">
              <img src={images[1] ? images[1] : Placeholder} alt="img" />
            </div>
            <div className="tile image-wrapper">
              <img src={images[2] ? images[2] : Placeholder} alt="img" />
            </div>
          </div>
        </>
      ) : (
        <div className="tile is-parent is-12">
          <div
            className="tile single-image-wrapper"
            style={{
              background: `url(${singleImage}) no-repeat center`,
              backgroundSize: 'cover',
            }}
          />
        </div>
      )}
    </div>
  );
};
