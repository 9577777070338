import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

import InventoryInformationAttributeAccordion from '../../atoms/InventoryInformationAttributeAccordion/inventory-information-attribute-accordion';
import './inventory-audience-info.scss';

export default ({ marketInfo }) => {
  return (
    <div>
      <div className="is-size-5 has-text-weight-semibold">
        About this market
      </div>
      <section className="accordions inventory-audience-info__content">
        <InventoryInformationAttributeAccordion
          accordionHeaderInformation={{
            icon: 'fas fa-chart-line',
            mainText: 'Population',
            secondaryText:
              marketInfo &&
              marketInfo.population &&
              marketInfo.population.total ? (
                <NumberFormat
                  value={marketInfo.population.total}
                  thousandSeparator={true}
                  displayType="text"
                  renderText={(value) =>
                    `This market has a population density of ${marketInfo.population.populationDensityPerSquare} per square km, and has a total population of ${value} residents.`
                  }
                />
              ) : (
                'Population data is not yet available for the asset.'
              ),
          }}
        />
        <InventoryInformationAttributeAccordion
          accordionHeaderInformation={{
            icon: 'fas fa-chart-pie',
            mainText: 'Demographics',
            secondaryText:
              marketInfo &&
              marketInfo.demographics &&
              marketInfo.demographics.visibleMinority &&
              marketInfo.demographics.visibleMinority.length
                ? undefined
                : 'Demographic data is not yet available for this asset.',
          }}
          tabularData={
            marketInfo &&
            marketInfo.demographics &&
            marketInfo.demographics.visibleMinority &&
            marketInfo.demographics.visibleMinority.length
              ? {
                  columns: ['Characteristics', 'Total', 'Male', 'Female'],
                  data: [...marketInfo.demographics.visibleMinority],
                }
              : undefined
          }
        />
        <InventoryInformationAttributeAccordion
          accordionHeaderInformation={{
            icon: 'far fa-user',
            mainText: 'Median Age',
            secondaryText:
              marketInfo &&
              marketInfo.population &&
              marketInfo.population.medianAge
                ? `This market has a median age of ${marketInfo.population.medianAge.average} years old. The average male is ${marketInfo.population.medianAge.male} years. The average female is ${marketInfo.population.medianAge.female} years old.`
                : 'Median Age data is not yet available for this asset.',
          }}
        />
        <InventoryInformationAttributeAccordion
          accordionHeaderInformation={{
            icon: 'fas fa-money-bill',
            mainText: 'Income',
            secondaryText:
              marketInfo &&
              marketInfo.income &&
              marketInfo.income.medianHousehold ? (
                <NumberFormat
                  value={marketInfo.income.medianHousehold}
                  thousandSeparator={true}
                  prefix="$"
                  displayType="text"
                  renderText={(value) =>
                    `This market has a median household income of ${value} per year.`
                  }
                />
              ) : (
                'Median income data is not yet available for the asset.'
              ),
          }}
        />
        <InventoryInformationAttributeAccordion
          accordionHeaderInformation={{
            icon: 'fas fa-suitcase',
            mainText: 'Journey To Work',
            secondaryText:
              marketInfo &&
              marketInfo.journeyToWork &&
              marketInfo.journeyToWork.asDriver &&
              marketInfo.journeyToWork.asPassenger ? (
                <Fragment>
                  <NumberFormat
                    value={marketInfo.journeyToWork.asDriver}
                    thousandSeparator={true}
                    displayType="text"
                    renderText={(value) => (
                      <p>
                        This market has an average of {value} people that drive
                        to work.
                      </p>
                    )}
                  />
                  <NumberFormat
                    value={marketInfo.journeyToWork.asPassenger}
                    thousandSeparator={true}
                    displayType="text"
                    renderText={(value) => (
                      <p>
                        Meanwhile, an average of {value} people utilize public
                        transit for their daily commute.
                      </p>
                    )}
                  />
                </Fragment>
              ) : (
                'Journey To Work data is not yet available for the asset.'
              ),
          }}
        />
      </section>
      <div className="inventory-audience-info__statcan-link-wrapper">
        <a
          className="is-link has-text-weight-semibold"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.statcan.gc.ca/eng/start"
        >
          <span>Source: Statistics Canada</span>
          <span className="icon">
            <i className="fas fa-long-arrow-alt-right" />
          </span>
        </a>
      </div>
    </div>
  );
};
