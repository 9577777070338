import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

import { AuthUserContext } from '../../../utils/Session';

import { INVENTORIES } from '../../../constants/routes';
import { supplierDocs } from '../../../models/suppliers';

import InventoryPlaceholder from '../../../assets/inventory-placeholder-square.png';

import './inventory-supplier-info.scss';

export default ({ firebase, inventory, assetTypes }) => {
  const authUser = useContext(AuthUserContext);
  const [shareDropdownButton, setShareDropdownButton] = useState(false);

  const whiteLabeledView = localStorage.getItem('supplier');

  const supplierLogo = inventory.supplier.logo;

  const moreAssetsUrl = whiteLabeledView
    ? `${INVENTORIES}/${whiteLabeledView}`
    : `${INVENTORIES}/?market=${
        supplierDocs[inventory.supplier.name].baseMarket
      }&nearbySearch=1&suppliers=${inventory.supplier.name}`;

  const shareMessageTitle = `Adsight advertising asset in ${inventory.city} - ${inventory.internalId}`;

  const shareMessageBody = `Check out this advertising asset on adsight\n `;

  const firebaseAnalytics = (analyticTerm) =>
    firebase.useAnalytics(analyticTerm, {
      user: authUser ? authUser.email : null,
      inventory: {
        internalId: inventory.internalId,
        supplier: inventory.supplier.name,
      },
    });

  const StreetViewfirebaseAnalytics = () =>
    firebase.useAnalytics('street_view_clicked', {
      user: authUser ? authUser.email : null,
      googleStreetViewUrl: buildStreetViewUrl(),
      inventory: {
        internalId: inventory.internalId,
        supplier: inventory.supplier.name,
      },
    });

  const beforeOnClickEmailShareButton = () => {
    firebaseAnalytics('email_share_inventory');
    return closeShareDropdown();
  };

  const closeShareDropdown = () => setShareDropdownButton(false);

  const toggleShareDropdown = () =>
    setShareDropdownButton(!shareDropdownButton);

  // Currently resorting to default pitch/heading/fov due to the lack of inventory-specific information.
  // Easiest way to customize viewpoint would be to save panoramaID for each inventory:
  // https://developers.google.com/maps/documentation/urls/guide#street-view-action
  const buildStreetViewUrl = () => {
    const { latitude, longitude } = inventory.coordinates;
    const GMAP_SVIEW_BASE_URL = new URL('https://www.google.com/maps/@?api=1');
    const params = {
      api: 1,
      map_action: 'pano',
      viewpoint: `${latitude},${longitude}`,
    };
    Object.entries(params).forEach(([param, value]) => {
      GMAP_SVIEW_BASE_URL.searchParams.append(param, value);
    });

    return GMAP_SVIEW_BASE_URL.href;
  };
  buildStreetViewUrl();

  return (
    <article className="media">
      <figure className="media-left">
        <div className="image is-64x64">
          <img
            className="is-rounded"
            src={supplierLogo || InventoryPlaceholder}
            alt="img"
          />
        </div>
      </figure>
      <div className="media-content">
        <div className="content">
          <div className="supplier-info__display-text">
            {supplierDocs[inventory.supplier.name].displayText}
          </div>
          <div>
            Servicing over{' '}
            {supplierDocs[inventory.supplier.name].markets.length} cities in
            Canada
          </div>
          <div className="supplier-info__more-assets-link">
            <Link to={moreAssetsUrl}>
              <span>View More Assets</span>
              <span className="icon">
                <i className="fas fa-arrow-right" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="media-right">
        <div className="supplier-info__action-button">
          {assetTypes[inventory.type].isStatic && (
            <a
              className="button has-text-weight-semibold"
              target="_blank"
              href={buildStreetViewUrl()}
              rel="noopener noreferrer"
            >
              <div onClick={StreetViewfirebaseAnalytics}>
                <span className="icon">
                  <i className="far fa-map" />
                </span>
                <span> Street View </span>
              </div>
            </a>
          )}
        </div>
        <div className="supplier-info__action-button">
          <div
            className={classNames('dropdown supplier-info__share-dropdown', {
              'is-active': shareDropdownButton,
            })}
          >
            <div className="dropdown-trigger supplier-info__share-dropdown__trigger">
              <button
                className="button has-text-weight-semibold"
                aria-haspopup
                aria-controls="share-menu"
                onClick={toggleShareDropdown}
              >
                <span className="icon">
                  <i className="fas fa-share-alt" />
                </span>
                <span> Share </span>
              </button>
            </div>
            <div className="dropdown-menu" id="share-menu" role="menu">
              <div className="dropdown-content">
                <EmailShareButton
                  resetButtonStyle={true}
                  className="supplier-info__share-dropdown__content-item is-size-6 has-text-weight-semibold"
                  beforeOnClick={beforeOnClickEmailShareButton}
                  url={window.location.href}
                  subject={shareMessageTitle}
                  body={shareMessageBody}
                >
                  Via Email
                </EmailShareButton>

                <FacebookShareButton
                  className="supplier-info__share-dropdown__content-item is-size-6 has-text-weight-semibold"
                  beforeOnClick={() =>
                    firebaseAnalytics('facebook_share_inventory')
                  }
                  onClick={closeShareDropdown}
                  url={window.location.href}
                  quote={shareMessageBody}
                >
                  Via Facebook
                </FacebookShareButton>

                <TwitterShareButton
                  className="supplier-info__share-dropdown__content-item is-size-6 has-text-weight-semibold"
                  beforeOnClick={() =>
                    firebaseAnalytics('twitter_share_inventory')
                  }
                  onClick={closeShareDropdown}
                  url={window.location.href}
                  title={shareMessageTitle}
                >
                  Via Twitter
                </TwitterShareButton>

                <LinkedinShareButton
                  className="supplier-info__share-dropdown__content-item is-size-6 has-text-weight-semibold"
                  beforeOnClick={() =>
                    firebaseAnalytics('linkedin_share_inventory')
                  }
                  onClick={closeShareDropdown}
                  url={window.location.href}
                  title={shareMessageTitle}
                  summary={shareMessageBody}
                >
                  Via LinkedIn
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
